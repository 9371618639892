@import url("theme/antd-customized.css") all;
@import url("theme/tailwind.css") all;

.context.top-left {
  top: var(--gap);
  left: var(--gap);
}

.context.top-right {
  top: var(--gap);
  right: var(--gap);
}

.context.bottom-left {
  bottom: var(--gap);
  left: var(--gap);
}

.context.bottom-right {
  bottom: var(--gap);
  right: var(--gap);
}

.context.center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.context-sub-menu,
.context-menu {
  position: fixed;
  background: #fff;
  z-index: 9999999;
  width: 120px;
  margin: 0;
  padding: 5px 0;
  border-radius: 2px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  font-size: 12px;
}

.context-menu .context-menu-item {
  height: 30px;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #f2f2f2;
}

.context-menu .context-menu-item span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.context-menu-item:last-of-type {
  border-bottom: none;
}

.context-menu .context-menu-item:hover {
  background: #f2f2f2;
}

.context-menu .context-sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
  width: 100px;
}

.context-menu .context-menu-item:hover > .context-sub-menu {
  display: block;
}

.context-menu.left .context-sub-menu {
  left: 0;
  transform: translateX(-100%);
}

.context-menu.top .context-sub-menu {
  top: 100%;
  transform: translateY(-100%);
}

.context-menu.left.top .context-sub-menu {
  transform: translate(-100%, -100%);
}

.ant-btn-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.ant-btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.ant-btn-primary:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity)) !important;
}
