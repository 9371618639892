@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .layer-global-loader {
    @apply w-screen h-screen flex flex-col animate-pulse bg-white absolute top-0 left-0 -ml-24 -mt-24;
  }
  .sidebar-item {
    @apply h-10 py-2 flex items-center my-2 cursor-pointer rounded-lg px-3;
  }
  .next-global-loader img {
    @apply w-20;
  }
  .default-nav {
    @apply hover:bg-gray-200 hover:text-gray-800;
  }
  .active-nav {
    @apply text-blue-800 font-semibold bg-blue-100 border-r-4 border-blue-500 hover:bg-blue-200 hover:text-blue-800;
  }
  .next-button {
    @apply cursor-pointer px-4 py-2 rounded-md h-8 flex items-center;
  }
  .primary-button {
    @apply bg-blue-600 hover:bg-blue-500 text-white hover:text-white;
  }
  .primary-button:disabled {
    @apply bg-blue-400 hover:bg-blue-400 hover:text-gray-100 text-gray-100 cursor-not-allowed;
  }
  .success-button {
    @apply bg-green-600 hover:bg-green-700 text-white hover:text-white;
  }
  .success-button:disabled {
    @apply bg-green-400 hover:bg-green-400 hover:text-gray-100 text-gray-100 cursor-not-allowed;
  }
  .default-button {
    @apply bg-gray-200 hover:bg-gray-300 text-black hover:text-black;
  }
  .default-button:disabled {
    @apply bg-gray-100 hover:bg-gray-100 hover:text-gray-400 text-gray-400 cursor-not-allowed;
  }
  .error-button {
    @apply bg-red-600 hover:bg-red-500 text-white hover:text-white;
  }
  .chip {
    @apply inline-block rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2;
  }
  .next-input input:disabled {
    @apply text-gray-500;
  }
  .next-input-center input {
    @apply text-center;
  }
}

@layer utilities {
  .input:focus + span .prefix-icon {
    color: theme(colors.indigo.500);
  }
  .input-error:focus + span .prefix-icon {
    color: theme(colors.red.500);
  }
}
