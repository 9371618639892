table tbody tr.next-selected-item {
  position: relative;
}
table tbody tr.next-selected-item td {
  z-index: 2;
  position: relative;
}
tr.next-selected-item > td {
  background-color: rgb(219 234 254 / var(--tw-bg-opacity)) !important;
}
.next-selected-item .ant-table-cell-fix-left {
  z-index: 10 !important;
}
table tbody tr.next-due-item {
  position: relative;
}
table tbody tr.next-due-item td {
  z-index: 2;
  position: relative;
}
tr.next-due-item > td {
  background-color: rgb(254 202 202 / var(--tw-bg-opacity)) !important;
}
.next-due-item .ant-table-cell-fix-left {
  z-index: 10 !important;
}
.gx-custom-datepicker-panel .ant-picker-panel:last-child {
  display: none;
}
.next-scroll > div:nth-of-type(1) {
  margin-right: -17px !important;
  margin-bottom: -17px !important;
  max-height: calc(100vh - 8rem + 17px) !important;
}
.gx-custom-calendar-content {
  width: 150px;
}
.gx-custom-calendar-content .gx-custom-calendar-content__item {
  padding: 5px 5px;
  font-weight: 500;
  cursor: pointer;
}
.gx-custom-calendar-content .gx-custom-calendar-content__item:hover {
  color: #1d4ed8;
  background-color: rgba(29, 78, 216, 0.05);
}
.gx-custom-calendar-content .gx-custom-calendar-content__item.gx-active {
  background-color: rgba(29, 78, 216, 0.1);
  color: #1d4ed8;
}
.ant-pagination-item-link {
  display: flex !important;
  justify-items: center !important;
}
.ant-pagination-item-link span {
  margin: auto !important;
}
.ant-form-item-control-input input:disabled {
  color: #727272;
}
